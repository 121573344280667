<template>
  <div class="digital-marketing-page">
    <h1 class="main-heading">Marketing Digital</h1>
    <section class="digital-marketing-content">
      <div class="digital-marketing-item animate__animated animate__fadeInLeft" style="animation-delay: 0s;">
        <img src="/images/digital-strategy.jpg" alt="Stratégie Digitale" class="digital-marketing-image" />
        <div class="digital-marketing-text">
          <h2>Stratégie Digitale</h2>
          <p>Chez CyberGlow, nous développons des stratégies digitales personnalisées pour répondre aux besoins spécifiques de votre entreprise. Notre approche intègre le référencement, les réseaux sociaux, le marketing de contenu et bien plus encore pour vous aider à atteindre vos objectifs.</p>
        </div>
      </div>

      <div class="digital-marketing-item animate__animated animate__fadeInRight" style="animation-delay: 0.2s;">
        <img src="/images/social-media.jpg" alt="Marketing des Réseaux Sociaux" class="digital-marketing-image" />
        <div class="digital-marketing-text">
          <h2>Marketing des Réseaux Sociaux</h2>
          <p>Nos experts en réseaux sociaux créent et gèrent des campagnes attractives sur diverses plateformes pour engager votre audience, augmenter votre visibilité et convertir vos abonnés en clients fidèles.</p>
        </div>
      </div>

      <div class="digital-marketing-item animate__animated animate__fadeInLeft" style="animation-delay: 0.4s;">
        <img src="/images/content-marketing.jpg" alt="Marketing de Contenu" class="digital-marketing-image" />
        <div class="digital-marketing-text">
          <h2>Marketing de Contenu</h2>
          <p>Nous produisons du contenu de haute qualité qui capte l'attention de votre public cible. Articles de blog, vidéos, infographies – notre équipe crée des contenus engageants qui renforcent votre marque et stimulent le trafic vers votre site web.</p>
        </div>
      </div>

      <div class="digital-marketing-item animate__animated animate__fadeInRight" style="animation-delay: 0.6s;">
        <img src="/images/seo-services.jpg" alt="Services SEO" class="digital-marketing-image" />
        <div class="digital-marketing-text">
          <h2>Services SEO</h2>
          <p>Notre équipe de spécialistes SEO optimise votre site web pour améliorer sa visibilité sur les moteurs de recherche. Grâce à des techniques de pointe, nous vous aidons à attirer un trafic qualifié et à améliorer votre positionnement dans les résultats de recherche.</p>
        </div>
      </div>

      <div class="digital-marketing-item animate__animated animate__fadeInLeft" style="animation-delay: 0.8s;">
        <img src="/images/email-marketing.jpg" alt="Email Marketing" class="digital-marketing-image" />
        <div class="digital-marketing-text">
          <h2>Email Marketing</h2>
          <p>Nous concevons des campagnes d'emailing efficaces pour garder le contact avec vos clients, promouvoir vos produits ou services, et augmenter vos ventes. Nos emails sont personnalisés et optimisés pour maximiser leur taux d'ouverture et de conversion.</p>
        </div>
      </div>

      <div class="contact-section">
        <h2><strong>Contactez-nous</strong></h2>
        <p>Prêt à transformer votre présence en ligne ? Contactez-nous dès aujourd'hui pour discuter de votre projet et découvrir comment nous pouvons vous aider à atteindre vos objectifs avec une stratégie de marketing digital efficace et personnalisée.</p>
        <br>
        <router-link to="/contact" class="contact-button">Nous Contacter</router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'DigitalMarketingPage',
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import 'animate.css';

.digital-marketing-page {
  text-align: center;
  color: #333;
  padding: 225px 20px 20px; /* Ajout de padding pour compenser la navbar fixe */
  background-color: inherit;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-heading {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #00aaff;
}

.digital-marketing-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.digital-marketing-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s;
}

.digital-marketing-item:hover {
  transform: translateY(-10px);
}

.digital-marketing-image {
  width: 100%;
  height: auto;
  max-width: 800px;
  max-height: 450px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.digital-marketing-text {
  text-align: left;
  max-width: 800px;
}

.digital-marketing-text h2 {
  margin-top: 0;
  font-size: 1.8em;
  color: #00aaff;
}

.digital-marketing-text p {
  margin-bottom: 25px;
}

.contact-section {
  text-align: center;
  margin-top: 60px;
}

.contact-button {
  display: inline-block;
  background-color: #00aaff;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.contact-button:hover {
  background-color: #00ffff;
  color: #333;
}
</style>
