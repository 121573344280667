<template>
  <footer class="footer">
    <div class="social-icons">
      <a href="https://www.linkedin.com" target="_blank" class="icon-link">
        <font-awesome-icon :icon="['fab', 'linkedin']" />
      </a>
      <a href="https://www.facebook.com" target="_blank" class="icon-link">
        <font-awesome-icon :icon="['fab', 'facebook']" />
      </a>
      <a href="https://www.instagram.com" target="_blank" class="icon-link">
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </a>
    </div>
    <p>
      Made with <span class="heart">♥</span> by CyberGlow
    </p>
    <div class="legal-links">
      <router-link to="/legal-mentions" class="legal-link"><small>Mentions légales</small></router-link>
      <router-link to="/terms-of-service" class="legal-link"><small>Conditions générales</small></router-link>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'SiteFooter',
};
</script>

<style scoped>
.footer {
  background-color: #111;
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
}

.social-icons {
  margin-bottom: 10px;
}

.icon-link {
  color: white;
  margin: 0 10px;
  font-size: 1.5em;
}

.heart {
  color: red;
}

.legal-links {
  margin-top: 10px;
}

.legal-link {
  color: #00aaff;
  margin: 0 10px;
  text-decoration: none;
}

.legal-link:hover {
  text-decoration: underline;
}
</style>
