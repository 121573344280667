<template>
  <div class="home-page">
    <section class="hero animate__animated animate__bounceIn" style="animation-delay: 0s;">
      <div class="hero-content">
        <div class="hero-text">
          <h1><strong>Boostez votre présence en ligne !</strong></h1>
          <br>
          <p>Découvrez nos services</p>
          <hr class="styled-hr" />
          <div class="services-list-container">
            <ul class="services-list">
              <li><router-link to="/services" class="link-hover">Création de votre site web personnalisé</router-link></li>
              <li><router-link to="/seo" class="link-hover">Optimisation pour les moteurs de recherche</router-link></li>
              <li><router-link to="/digital-marketing" class="link-hover">Marketing digital (réseaux sociaux, emailing)</router-link></li>
              <li><router-link to="/hosting" class="link-hover">Hébergement de votre site web ou application</router-link></li>
            </ul>
          </div>
          <hr class="styled-hr" />
          <p><strong>Propulsez votre entreprise au sommet.</strong></p>
        </div>
      </div>
    </section>

    <section class="services animate__animated animate__fadeInUp" style="animation-delay: 0.5s;">
      <h2>Nos Services</h2>
      <div class="products">
        <div class="product animate__animated animate__fadeInUp" style="animation-delay: 0.6s;" v-for="product in products" :key="product.id">
          <img :src="product.image" :alt="product.title" class="product-icon" />
          <h3>{{ product.title }}</h3>
          <p>{{ product.description }}</p>
          <router-link :to="product.link" class="discover-button">Découvrir</router-link>
        </div>
      </div>
    </section>

    <section class="testimonials animate__animated animate__fadeInUp" style="animation-delay: 1s;">
      <h2>Témoignages de Nos Clients</h2>
      <hr class="styled-hr" />
      <div class="testimonial animate__animated animate__fadeInUp" style="animation-delay: 1.2s;" v-for="testimonial in testimonials" :key="testimonial.id">
        <p>"{{ testimonial.quote }}"</p>
        <p class="client">
          - {{ testimonial.client }}
          <span v-if="testimonial.link">
            <a :href="testimonial.link" target="_blank">{{ testimonial.linkText }}</a>
          </span>
        </p>
        <hr class="styled-hr" />
      </div>
    </section>

    <section class="cta animate__animated animate__fadeInUp" style="animation-delay: 1.5s;">
      <h2>Prêt à Améliorer Votre Présence en Ligne ?</h2>
      <router-link to="/contact" class="cta-button">Contactez-nous</router-link>
    </section>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      products: [
        {
          id: 1,
          title: 'Construction de Sites Web',
          description: 'Nous créons des sites web modernes et réactifs adaptés à vos besoins.',
          image: '/images/web-development-icon.jpg',
          link: '/services'
        },
        {
          id: 2,
          title: 'Consultant SEO expert',
          description: 'Améliorez votre visibilité en ligne grâce à nos services de référencement.',
          image: '/images/seo-icon.jpg',
          link: '/seo'
        },
        {
          id: 3,
          title: 'Marketing Digital',
          description: 'Développez votre présence en ligne avec nos stratégies de marketing digital.',
          image: '/images/digital-marketing-icon.jpg',
          link: '/digital-marketing'
        },
        {
          id: 4,
          title: 'Hébergements performants',
          description: 'Bénéficiez d’un hébergement sécurisé et performant pour votre site web.',
          image: '/images/hosting-icon.jpg',
          link: '/hosting'
        },
      ],
      testimonials: [
        {
          id: 1,
          quote: 'CyberGlow a transformé notre présence en ligne.',
          client: 'Sebastien G.',
          link: 'https://www.niceevents.be',
          linkText: 'niceevents.be',
        },
        {
          id: 2,
          quote: 'Grâce à CyberGlow, nous avons augmenté notre trafic de 50%.',
          client: 'Joëlle G.',
          link: 'https://www.fleurdecelle.be',
          linkText: 'fleurdecelle.be',
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import 'animate.css';

.home-page {
  text-align: center;
  color: #333;
  padding: 225px 20px 20px; /* Ajout de padding pour compenser la navbar fixe */
  background-color: inherit; /* Inhère le background du parent */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero {
  background-color: #2a2a2a; /* Couleur de fond sombre et harmonieuse */
  padding: 50px 20px;
  color: #fff; /* Couleur de texte blanc pour contraste */
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1060px;
  opacity: 0.8;
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; /* Alignement en colonne pour s'assurer que tout est centré */
}

.hero-text {
  text-align: center;
}

.cyberglow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 2.5em;
}

.hero h1 {
  font-size: 2em;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1.2em;
}

.hero-logo {
  width: 64px;
  height: 64px;
  margin: 20px auto; /* Centrer le logo horizontalement */
  padding: 10px;
}

.services-list-container {
  display: flex;
  justify-content: center;
}

.services-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.services-list li {
  margin: 0 10px;
}

.services-list a {
  text-decoration: none;
  color: #00aaff;
  position: relative;
  display: inline-block;
  transition: color 0.3s ease-in-out;
}

.services-list a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 3px;
  background: #00aaff;
  opacity: 0;
  transform: scaleX(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.services-list a:hover::after {
  opacity: 1;
  transform: scaleX(1);
}

.link-hover {
  position: relative;
  color: #00aaff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.link-hover:hover {
  color: #00ffff;
}

.link-hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #00aaff, #00ffff);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.link-hover:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.styled-hr {
  border: 0;
  height: 1px;
  background: linear-gradient(to right, transparent, #00aaff, transparent);
  margin: 20px 0;
  animation: hr-animate 3s infinite;
}

@keyframes hr-animate {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.services, .testimonials, .cta {
  width: 100%;
  max-width: 1200px; /* Augmentation de la largeur maximale */
  margin-bottom: 30px;
}

.services h2, .testimonials h2, .cta h2 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.products {
  display: flex;
  justify-content: center; /* Centrer les produits horizontalement */
  flex-wrap: wrap; /* Permettre les produits de se casser en lignes multiples */
  gap: 20px;
}

.product {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 250px; /* Réduction de la largeur pour ajuster l'affichage sur une ligne */
  text-align: left;
  transition: transform 0.3s;
  flex-shrink: 0; /* Empêcher les produits de rétrécir en dessous de leur taille initiale */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrer le contenu */
}

.product:hover {
  transform: translateY(-10px);
}

.product-icon {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin-bottom: 20px;
}

.product h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  text-align: center;
}

.product p {
  font-size: 1em;
  text-align: center;
  margin-bottom: 15px; /* Ajout d'un espace entre le texte et le bouton */
}

.discover-button {
  margin-top: auto; /* Pousse le bouton vers le bas */
  display: inline-block;
  background-color: #00aaff;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
}

.discover-button:hover {
  background-color: #00ffff;
  color: #333;
}

.testimonials {
  background-color: #f0f8ff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1060px;
  opacity: 0.8;
}

.testimonial {
  margin-bottom: 20px;
}

.testimonial p {
  margin: 0;
}

.client {
  font-style: italic;
  color: #555;
}

.cta {
  background-color: #2a2a2a; /* Couleur de fond sombre et harmonieuse */
  color: white;
  padding: 30px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1060px;
  opacity: 0.8;
}

.cta-button {
  display: inline-block;
  background-color: #00aaff; /* Couleur de bouton bleu */
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
  background-color: #00ffff;
  color: #333;
}

/* Media queries pour les petits écrans */
@media (max-width: 600px) {
  .hero {
    padding: 20px 10px;
  }

  .hero-content {
    flex-direction: column;
  }

  .hero-text {
    max-width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .hero h1 {
    font-size: 1.5em;
  }

  .hero p {
    font-size: 1em;
  }

  .services-list {
    flex-direction: column;
    gap: 10px;
  }

  .products {
    flex-direction: column; /* Afficher les produits en colonne */
    align-items: center; /* Centrer les produits */
  }

  .product {
    width: 90%; /* Ajuster la largeur des produits */
    margin: 10px 0;
  }
}

.text-torus-color {
  color: #00aaff; /* Couleur bleue du torus */
}

.glow {
  color: #fff;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #0ff, 0 0 25px #0ff, 0 0 30px #0ff, 0 0 35px #0ff;
}
</style>
