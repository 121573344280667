<template>
  <section class="bg-white text-center py-20">
    <h1 class="text-4xl font-bold mb-4">Contact</h1>
    <p class="text-lg">Contactez-nous pour plus d'informations sur nos services.</p>
  </section>
</template>

<script>
export default {
  name: 'ContactPage',
};
</script>

<style scoped>
/* Styles spécifiques à ce composant */
</style>
