<template>
  <nav class="bg-gray-800 p-4 grid grid-cols-3 items-center fixed top-0 left-0 right-0 z-50 navbar-shadow">
    <div :class="['logo-container', 'flex', 'items-center', 'text-white', 'col-span-3', { 'small-screen-navbar': isMenuOpen && !isLargeScreen }]">
      <div :class="['ml-2', 'text-3xl', 'font-orbitron', 'flex', 'items-center', { 'small-screen': isMenuOpen && !isLargeScreen }]">
        <span id="cyber" class="text-torus-color">Cyber</span>
        <img src="/images/logo1.png" alt="Logo" class="logo mx-1" />
        <span id="glow" class="text-white glow">Glow</span>
      </div>
    </div>
    <div :class="['menu-container', 'lg:flex', 'lg:justify-center', 'lg:items-center', 'lg:col-span-2', { 'block': isMenuOpen, 'hidden': !isMenuOpen, 'menu-left': isMenuOpen && !isLargeScreen }]">
      <div class="text-sm lg:flex lg:space-x-4 font-orbitron">
        <router-link @click="closeMenu" to="/" class="menu-item flex items-center mt-4 lg:inline-block lg:mt-0 text-white hover:text-gray-400">
          <font-awesome-icon :icon="['fas', 'home']" class="mr-2"></font-awesome-icon>
          Accueil
        </router-link>
        <router-link @click="closeMenu" to="/services" class="menu-item flex items-center mt-4 lg:inline-block lg:mt-0 text-white hover:text-gray-400">
          <font-awesome-icon :icon="['fas', 'bell-concierge']" class="mr-2"></font-awesome-icon>
          Services
        </router-link>
        <router-link @click="closeMenu" to="/web-creation" class="menu-item flex items-center mt-4 lg:inline-block lg:mt-0 text-white hover:text-gray-400">
          <font-awesome-icon :icon="['fas', 'tools']" class="mr-2"></font-awesome-icon>
          Création
        </router-link>
        <router-link @click="closeMenu" to="/seo" class="menu-item flex items-center mt-4 lg:inline-block lg:mt-0 text-white hover:text-gray-400">
          <font-awesome-icon :icon="['fas', 'search']" class="mr-2"></font-awesome-icon>
          SEO
        </router-link>
        <router-link @click="closeMenu" to="/digital-marketing" class="menu-item flex items-center mt-4 lg:inline-block lg:mt-0 text-white hover:text-gray-400">
          <font-awesome-icon :icon="['fas', 'bullhorn']" class="mr-2"></font-awesome-icon>
          Marketing&nbsp;Digital
        </router-link>
        <router-link @click="closeMenu" to="/hosting" class="menu-item flex items-center mt-4 lg:inline-block lg:mt-0 text-white hover:text-gray-400">
          <font-awesome-icon :icon="['fas', 'server']" class="mr-2"></font-awesome-icon>
          Hébergement
        </router-link>
        <router-link @click="closeMenu" to="/contact" class="menu-item flex items-center mt-4 lg:inline-block lg:mt-0 text-white hover:text-gray-400">
          <font-awesome-icon :icon="['fas', 'envelope']" class="mr-2"></font-awesome-icon>
          Contact
        </router-link>
      </div>
    </div>
    <div class="block lg:hidden col-span-1 flex justify-end small-screen-burger">
      <button @click="toggleMenu" class="flex items-center px-3 py-2 border rounded text-white border-white hover:text-gray-400 hover:border-gray-400">
        <font-awesome-icon :icon="['fas', 'bars']" class="h-6 w-6"></font-awesome-icon>
      </button>
    </div>
    <div class="scrolling-line"></div>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
      isMenuOpen: false,
      isLargeScreen: window.innerWidth >= 1024
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    },
    handleResize() {
      this.isLargeScreen = window.innerWidth >= 1024;
    },
    applyRandomRotation() {
      const getRandomAngle = () => Math.random() * 4 - 2; // Generate a random angle between -2 and 2 degrees

      const cyber = document.getElementById('cyber');
      const glow = document.getElementById('glow');

      const rotateXCyber = getRandomAngle();
      const rotateYCyber = getRandomAngle();
      const rotateZCyber = getRandomAngle();

      const rotateXGlow = getRandomAngle();
      const rotateYGlow = getRandomAngle();
      const rotateZGlow = getRandomAngle();

      cyber.style.transform = `rotateX(${rotateXCyber}deg) rotateY(${rotateYCyber}deg) rotateZ(${rotateZCyber}deg)`;
      glow.style.transform = `rotateX(${rotateXGlow}deg) rotateY(${rotateYGlow}deg) rotateZ(${rotateZGlow}deg)`;
    }
  },
  mounted() {
    this.rotationInterval = setInterval(this.applyRandomRotation, 1000); // Apply random rotation every second
    window.addEventListener('resize', this.handleResize);
    this.$router.beforeEach((to, from, next) => {
      this.closeMenu();
      next();
    });
  },
  beforeUnmount() {
    clearInterval(this.rotationInterval); // Clear interval when component is destroyed
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import 'animate.css';

.font-orbitron {
  font-family: 'Orbitron', sans-serif;
}

.logo {
  width: 64px;
  height: 64px;
  margin: 20px auto; /* Centrer le logo horizontalement */
  padding: 10px;
  transition: transform 0.3s ease-in-out;
}

.text-torus-color {
  color: #00aaff; /* Même couleur que le torus 3D */
}

.glow {
  color: #fff;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px #0ff, 0 0 25px #0ff, 0 0 30px #0ff, 0 0 35px #0ff;
}

.menu-item {
  position: relative;
  transition: color 0.3s ease-in-out;
}

.menu-item::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 3px;
  background: #00aaff;
  opacity: 0;
  transform: scaleX(0);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.menu-item:hover::after {
  opacity: 1;
  transform: scaleX(1);
}

.menu-item:hover {
  color: #00aaff;
}

#cyber, #glow {
  transition: transform 3s ease-in-out;
}

.navbar-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.scrolling-line {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, rgba(0, 170, 255, 0), #00aaff, rgba(0, 170, 255, 0));
  animation: scroll-line 2s infinite;
}

@keyframes scroll-line {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.small-screen #cyber, .small-screen #glow {
  font-size: 1.2rem; /* Adjusted font size */
}

.small-screen .logo {
  width: 48px; /* Reduced width */
  height: 48px; /* Reduced height */
}

.menu-left {
  justify-content: flex-start !important; /* Align menu items to the left */
  padding-right: 1rem; /* Add some padding to the left */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: bounceInLeft 0.5s;
}

.flex.items-center.mt-4 {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.menu-item.flex.items-center.mt-4 .mr-2 {
  margin-right: 0.5rem;
}

.small-screen-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

@media (max-width: 1024px) {
  .logo-container {
    grid-column: span 3;
  }
  .menu-left {
    flex-direction: column;
    align-items: flex-start;
  }
  .flex.items-center.mt-4 {
    margin-top: 1rem;
  }
  .small-screen-navbar {
    order: 2;
  }
  .small-screen {
    order: 1;
  }
}

.small-screen-burger {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
