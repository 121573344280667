<template>
  <div class="web-creation-page">
    <h1 class="main-heading">Création WEB</h1>
    <section class="web-creation-content">
      <div class="web-creation-item animate__animated animate__fadeIn" style="animation-delay: 0s;">
        <img src="/images/modern-web-creation.jpg" alt="Création Web Moderne" class="web-creation-image" />
        <div class="web-creation-text">
          <h2>Création Web Moderne</h2>
          <p>Chez CyberGlow, nous nous spécialisons dans la création de sites web modernes et réactifs. Notre équipe s'assure que votre site soit à la fois esthétiquement agréable et fonctionnel sur tous les appareils.</p>
        </div>
      </div>

      <div class="web-creation-item animate__animated animate__fadeIn" style="animation-delay: 0.2s;">
        <img src="/images/creative-design.jpg" alt="Design Créatif" class="web-creation-image" />
        <div class="web-creation-text">
          <h2>Design Créatif</h2>
          <p>Nos designers créent des sites web innovants et captivants qui reflètent fidèlement l'identité de votre marque et captivent vos visiteurs dès le premier regard.</p>
        </div>
      </div>

      <div class="web-creation-item animate__animated animate__fadeIn" style="animation-delay: 0.4s;">
        <img src="/images/advanced-technology.jpg" alt="Technologie Avancée" class="web-creation-image" />
        <div class="web-creation-text">
          <h2>Technologie Avancée</h2>
          <p>Nous utilisons les dernières technologies pour garantir la performance et la rapidité de votre site. Chaque détail est optimisé pour offrir une expérience utilisateur fluide et agréable.</p>
        </div>
      </div>

      <div class="web-creation-item animate__animated animate__fadeIn" style="animation-delay: 0.6s;">
        <img src="/images/responsive-design.jpg" alt="Design Responsive" class="web-creation-image" />
        <div class="web-creation-text">
          <h2>Design Responsive</h2>
          <p>Nos sites web sont optimisés pour être accessibles et fonctionnels sur tous les appareils, assurant une expérience utilisateur optimale sur ordinateur, tablette et smartphone.</p>
        </div>
      </div>

      <div class="web-creation-item animate__animated animate__fadeIn" style="animation-delay: 0.8s;">
        <img src="/images/seo-optimization.jpg" alt="Optimisation SEO" class="web-creation-image" />
        <div class="web-creation-text">
          <h2>Optimisation SEO</h2>
          <p>Nous optimisons chaque page de votre site pour améliorer votre classement sur les moteurs de recherche, vous aidant ainsi à attirer plus de visiteurs et à augmenter votre visibilité en ligne.</p>
        </div>
      </div>

      <div class="contact-section">
        <h2><strong>Contactez-nous</strong></h2>
        <p>Prêt à transformer votre présence en ligne ? Contactez-nous dès aujourd'hui pour discuter de votre projet et découvrir comment nous pouvons vous aider à atteindre vos objectifs avec un site internet moderne et personnalisé.</p>
        <br>
        <router-link to="/contact" class="contact-button">Nous Contacter</router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'WebCreationPage',
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import 'animate.css';

.web-creation-page {
  text-align: center;
  color: #333;
  padding: 225px 20px 20px;
  background-color: inherit;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-heading {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #00aaff;
}

.web-creation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.web-creation-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s;
}

.web-creation-item:hover {
  transform: translateY(-10px);
}

.web-creation-image {
  width: 100%;
  height: auto;
  max-width: 800px;
  max-height: 450px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.web-creation-text {
  text-align: left;
  max-width: 800px;
}

.web-creation-text h2 {
  margin-top: 0;
  font-size: 1.8em;
  color: #00aaff;
}

.web-creation-text p {
  margin-bottom: 25px;
}

.contact-section {
  text-align: center;
  margin-top: 60px;
}

.contact-button {
  display: inline-block;
  background-color: #00aaff;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.contact-button:hover {
  background-color: #00ffff;
  color: #333;
}
</style>
