import * as THREE from 'three';

export function initBackground(containerId) {
  // Initialisation de la scène, de la caméra et du rendu
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  const renderer = new THREE.WebGLRenderer({ alpha: true });
  renderer.setSize(window.innerWidth, window.innerHeight);

  // Ajouter le canvas du rendu au document
  document.getElementById(containerId).appendChild(renderer.domElement);

  // Création de la géométrie du torus knot avec un matériau en wireframe
  const geometry = new THREE.TorusKnotGeometry(10, 3, 100, 16);
  const material = new THREE.MeshBasicMaterial({
    color: 0x666666,
    wireframe: true
  });
  const torusKnot = new THREE.Mesh(geometry, material);
  scene.add(torusKnot);

  // Position de la caméra
  camera.position.z = 50;

  // Variables pour stocker la position de la souris
  let mouseX = 0;
  let mouseY = 0;
  let targetX = 0;
  let targetY = 0;

  // Variables pour le mouvement automatique
  let autoRotateX = 0.002; // Réduire la vitesse de rotation automatique
  let autoRotateY = 0.002; // Réduire la vitesse de rotation automatique
  let lastMouseMoveTime = Date.now();
  const idleTime = 750;

  // Écouter les mouvements de la souris
  document.addEventListener('mousemove', (event) => {
    mouseX = (event.clientX / window.innerWidth) * 2 - 1;
    mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
    lastMouseMoveTime = Date.now();
  });

  // Fonction de rendu
  function animate() {
    requestAnimationFrame(animate);

    const now = Date.now();
    const elapsedTime = now - lastMouseMoveTime;

    if (elapsedTime > idleTime) {
      targetX += autoRotateX;
      targetY += autoRotateY;
    } else {
      targetX += (mouseX - targetX) * 0.05; // Augmenter l'effet de lissage
      targetY += (mouseY - targetY) * 0.05; // Augmenter l'effet de lissage
    }

    torusKnot.rotation.x = targetY * Math.PI;
    torusKnot.rotation.y = targetX * Math.PI;

    renderer.render(scene, camera);
  }

  // Lancer l'animation
  animate();

  // Ajustement de la taille du rendu lorsque la fenêtre est redimensionnée
  window.addEventListener('resize', () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    renderer.setSize(width, height);
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
  });

  return { scene, camera, renderer, torusKnot };
}

export function onWindowResize(camera, renderer) {
  const width = window.innerWidth;
  const height = window.innerHeight;
  renderer.setSize(width, height);
  camera.aspect = width / height;
  camera.updateProjectionMatrix();
}

export function onMouseMove(event, torusKnot) {
  const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
  const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;

  torusKnot.rotation.x = mouseY * Math.PI;
  torusKnot.rotation.y = mouseX * Math.PI;
}
