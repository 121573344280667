<template>
  <div class="service-page">
    <h1 class="main-heading">Services</h1>
    <section class="service-content">
      <div class="service-item animate__animated animate__fadeInUp" style="animation-delay: 0s;">
        <img src="/images/welcome.jpg" alt="Création de Sites Internet Modernes et Adaptés" class="service-image" />
        <div class="service-text">
          <h3>Création de Sites Internet Modernes et Adaptés</h3>
          <hr class="styled-hr" />
          <p>Bienvenue chez CyberGlow, votre expert en création de sites internet. Nous concevons des sites web modernes, design et adaptés à vos besoins spécifiques pour vous aider à vous démarquer en ligne.</p>
          <router-link to="/services/creation-sites-web" class="discover-button">Découvrir</router-link>
        </div>
      </div>
      <div class="service-item animate__animated animate__fadeInUp" style="animation-delay: 0s;">
        <img src="/images/design-innovant.jpg" alt="Design Innovant et Attractif" class="service-image" />
        <div class="service-text">
          <h3>Design Innovant et Attractif</h3>
          <hr class="styled-hr" />
          <p>Nous savons que la première impression est cruciale. C'est pourquoi nos designers créent des sites web esthétiques et captivants. Chaque interface est conçue pour refléter fidèlement l'identité de votre marque et attirer vos visiteurs dès le premier regard.</p>
          <router-link to="/services/design-innovant" class="discover-button">Découvrir</router-link>
        </div>
      </div>
      
      <div class="service-item animate__animated animate__fadeInUp" style="animation-delay: 0.2s;">
        <img src="/images/technologies-modernes.jpg" alt="Technologies de Pointe" class="service-image" />
        <div class="service-text">
          <h3>Technologies de Pointe</h3>
          <hr class="styled-hr" />
          <p>Nous utilisons les dernières technologies pour garantir la performance et la rapidité de votre site. Que ce soit pour l'intégration d'animations interactives ou l'optimisation de la vitesse de chargement, nous veillons à offrir une expérience utilisateur fluide et agréable.</p>
          <router-link to="/services/technologies" class="discover-button">Découvrir</router-link>
        </div>
      </div>
      
      <div class="service-item animate__animated animate__fadeInUp" style="animation-delay: 0.4s;">
        <img src="/images/sur-mesure.jpg" alt="Solutions Sur Mesure" class="service-image" />
        <div class="service-text">
          <h3>Solutions Sur Mesure</h3>
          <hr class="styled-hr" />
          <p>Chez CyberGlow, chaque projet est unique. Nous analysons vos objectifs et vos besoins pour créer une solution sur mesure. Que vous ayez besoin d'un site vitrine pour présenter vos services, d'un site e-commerce pour vendre vos produits, ou d'une plateforme avec des fonctionnalités spécifiques, nous avons la solution idéale.</p>
          <router-link to="/services/sur-mesure" class="discover-button">Découvrir</router-link>
        </div>
      </div>
      
      <div class="service-item animate__animated animate__fadeInUp" style="animation-delay: 0.6s;">
        <img src="/images/multi-plateforme.jpg" alt="Multi-Plateforme et Responsive" class="service-image" />
        <div class="service-text">
          <h3>Multi-Plateforme et Responsive</h3>
          <hr class="styled-hr" />
          <p>Avec l'augmentation de l'accès à Internet via des appareils mobiles, il est essentiel que votre site soit accessible et performant sur tous les appareils. Nos sites web sont optimisés pour être responsive, garantissant une expérience utilisateur optimale sur ordinateur, tablette et smartphone.</p>
          <router-link to="/services/multi-plateforme" class="discover-button">Découvrir</router-link>
        </div>
      </div>
      
      <div class="service-item animate__animated animate__fadeInUp" style="animation-delay: 0.8s;">
        <img src="/images/optimisation-seo.jpg" alt="Optimisation SEO" class="service-image" />
        <div class="service-text">
          <h3>Optimisation SEO</h3>
          <hr class="styled-hr" />
          <p>La visibilité sur les moteurs de recherche est essentielle pour attirer des visiteurs. Nos experts en SEO travaillent à optimiser chaque page de votre site, de la structure à la rédaction de contenu, pour améliorer votre classement sur Google et autres moteurs de recherche.</p>
          <router-link to="/services/seo" class="discover-button">Découvrir</router-link>
        </div>
      </div>

      <div class="service-item animate__animated animate__fadeInUp" style="animation-delay: 0.8s;">
        <img src="/images/marketing-digital.jpg" alt="Marketing Digital" class="service-image" />
        <div class="service-text">
          <h3>Marketing Digital</h3>
          <hr class="styled-hr" />
          <p>Maximisez votre visibilité en ligne grâce à notre service de marketing digital. Nous élaborons des stratégies personnalisées incluant le référencement payant (SEA), le marketing des réseaux sociaux, le content marketing et l'email marketing. Notre objectif est de vous aider à atteindre et engager votre audience cible, augmenter votre trafic et convertir vos visiteurs en clients fidèles.</p>
          <router-link to="/services/marketing-digital" class="discover-button">Découvrir</router-link>
        </div>
      </div>
      
      <div class="service-item animate__animated animate__fadeInUp" style="animation-delay: 1s;">
        <img src="/images/serveurs-performants.jpg" alt="Hébergement Sécurisé et Performant" class="service-image" />
        <div class="service-text">
          <h3>Hébergement Sécurisé et Performant</h3>
          <hr class="styled-hr" />
          <p>Nous offrons également des services d'hébergement sur des serveurs sécurisés et réactifs. Notre infrastructure de pointe garantit la disponibilité et la rapidité de votre site web, tout en assurant une protection maximale contre les cybermenaces. Avec notre service d'hébergement, vous bénéficiez d'une tranquillité d'esprit totale, sachant que votre site est entre de bonnes mains.</p>
          <router-link to="/services/hebergement" class="discover-button">Découvrir</router-link>
        </div>
      </div>

      <div class="service-item animate__animated animate__fadeInUp" style="animation-delay: 1s;">
        <img src="/images/support-maintenance.jpg" alt="Support et Maintenance" class="service-image" />
        <div class="service-text">
          <h3>Support et Maintenance</h3>
          <hr class="styled-hr" />
          <p>Nous ne nous contentons pas de créer votre site web. Nous offrons également des services de support et de maintenance pour garantir que votre site reste à jour et sécurisé. Notre équipe est toujours disponible pour répondre à vos questions et effectuer les mises à jour nécessaires, vous permettant de vous concentrer sur votre activité principale.</p>
          <router-link to="/services/support-maintenance" class="discover-button">Découvrir</router-link>
        </div>
      </div>
      
      <div class="contact-section">
        <h2><strong>Contactez-nous</strong></h2>
        <p>Prêt à transformer votre présence en ligne ? Contactez-nous dès aujourd'hui pour discuter de votre projet et découvrir comment nous pouvons vous aider à atteindre vos objectifs avec un site internet moderne et personnalisé.</p>
        <br>
        <router-link to="/contact" class="contact-button">Nous Contacter</router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ServicePage',
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import 'animate.css';

.service-page {
  text-align: center;
  color: #333;
  padding: 225px 20px 20px; /* Ajout de padding pour compenser la navbar fixe */
  background-color: inherit; /* Inhère le background du parent */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-heading {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #00aaff;
}

.styled-hr {
  border: 0;
  height: 1px;
  background: linear-gradient(to right, transparent, #00aaff, transparent);
  margin: 20px 0;
  animation: hr-animate 3s infinite;
}

.service-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.service-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  background-color: #fff; /* White background for contrast */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px; /* Internal padding */
  transition: transform 0.3s;
  position: relative;
}

.service-item:hover {
  transform: translateY(-10px); /* Lift on hover */
}

.service-image {
  width: 300px; /* Taille fixe en largeur */
  height: 300px; /* Taille fixe en hauteur */
  object-fit: cover; /* Ajuster l'image pour qu'elle couvre l'espace */
  border-radius: 8px; /* Rounded corners for images */
  margin-bottom: 20px;
}

.service-text {
  text-align: left;
  max-width: 800px;
}

.service-text h3 {
  margin-top: 0;
  font-size: 1.5em;
}

.service-text p {
  margin-bottom: 25px; /* Ajout d'un espace entre le texte et le bouton pour petit écran */
}

.discover-button {
  display: inline-block;
  background-color: #00aaff;
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.discover-button:hover {
  background-color: #00ffff;
  color: #333;
}

.contact-section {
  text-align: center;
  margin-top: 60px;
}

.contact-button {
  display: inline-block;
  background-color: #00aaff;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.contact-button:hover {
  background-color: #00ffff;
  color: #333;
}

@media (min-width: 768px) {
  .service-item {
    flex-direction: row;
  }
  .service-text {
    text-align: left;
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .service-text p {
    margin-bottom: 60px; /* Ajout d'un espace entre le texte et le bouton pour petit écran */
  }
}
</style>
