<template>
  <div :class="pageClass">
    <NavigationBar />
    <div id="three-container"></div>
    <router-view />
    <SiteFooter />
    <CookieConsent />
  </div>
</template>

<script>
import NavigationBar from './components/NavigationBar.vue';
import SiteFooter from './components/SiteFooter.vue';
import CookieConsent from './components/CookieConsent.vue';
import { initBackground, onWindowResize, onMouseMove } from './assets/backgrounds.js';

export default {
  name: 'App',
  components: {
    NavigationBar,
    SiteFooter,
    CookieConsent
  },
  computed: {
    pageClass() {
      const routeName = this.$route.name;
      return {
        'home-page': routeName === 'home',
        'services-page': routeName === 'services',
        'web-creation-page': routeName === 'web-creation',
        'seo-page': routeName === 'seo',
        'digital-marketing-page': routeName === 'digital-marketing',
        'hosting-page': routeName === 'hosting',
        'contact-page': routeName === 'contact'
      };
    }
  },
  mounted() {
    const { scene, camera, renderer, torusKnot } = initBackground('three-container');
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.torusKnot = torusKnot;

    window.addEventListener('resize', this.handleResize, false);
    window.addEventListener('mousemove', this.handleMouseMove, false);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize, false);
    window.removeEventListener('mousemove', this.handleMouseMove, false);
  },
  methods: {
    handleResize() {
      onWindowResize(this.camera, this.renderer);
    },
    handleMouseMove(event) {
      onMouseMove(event, this.torusKnot);
    }
  }
};
</script>

<style scoped>
@import './assets/styles.css';

#three-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place the background behind other elements */
  overflow: hidden; /* Prevent scrollbars */
}
</style>
