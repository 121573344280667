import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import Services from '../components/ServicesPage.vue';
import WebCreationPage from '../components/WebCreationPage.vue';
import SEOPage from '../components/SEOPage.vue';
import DigitalMarketingPage from '../components/DigitalMarketingPage.vue';
import HostingPage from '../components/HostingPage.vue';
import ContactPage from '../components/ContactPage.vue';

const routes = [
  { path: '/', name: 'home', component: HomePage },
  { path: '/services', name: 'services', component: Services },
  { path: '/web-creation', name: 'web-creation', component: WebCreationPage },
  { path: '/seo', name: 'seo', component: SEOPage },
  { path: '/digital-marketing', name: 'digital-marketing', component: DigitalMarketingPage },
  { path: '/hosting', name: 'hosting', component: HostingPage },
  { path: '/contact', name: 'contact', component: ContactPage },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
