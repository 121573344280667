import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import 'animate.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faHome, faTools, faSearch, faBullhorn, faServer, faEnvelope, faBellConcierge } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faBars, faHome, faTools, faSearch, faBullhorn, faServer, faEnvelope, faBellConcierge, faLinkedin, faFacebook, faInstagram);

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.mount('#app');
