<template>
  <div class="seo-page">
    <h1 class="main-heading">SEO</h1>
    <section class="seo-content">
      <div class="seo-item animate__animated animate__fadeIn" style="animation-delay: 0s;">
        <img src="/images/seo-analysis.jpg" alt="Analyse SEO" class="seo-image" />
        <div class="seo-text">
          <h2>Analyse SEO Complète</h2>
          <p>Chez CyberGlow, nous réalisons une analyse complète de votre site pour identifier les opportunités d'amélioration SEO. Notre objectif est de vous aider à atteindre les premières positions sur les moteurs de recherche.</p>
        </div>
      </div>

      <div class="seo-item animate__animated animate__fadeIn" style="animation-delay: 0.2s;">
        <img src="/images/keyword-research.jpg" alt="Recherche de Mots-Clés" class="seo-image" />
        <div class="seo-text">
          <h2>Recherche de Mots-Clés</h2>
          <p>Nous effectuons une recherche approfondie des mots-clés pertinents pour votre activité afin de cibler efficacement votre audience et d'augmenter votre visibilité en ligne.</p>
        </div>
      </div>

      <div class="seo-item animate__animated animate__fadeIn" style="animation-delay: 0.4s;">
        <img src="/images/on-page-optimization.jpg" alt="Optimisation On-Page" class="seo-image" />
        <div class="seo-text">
          <h2>Optimisation On-Page</h2>
          <p>Nous optimisons chaque élément de votre site, des balises méta aux contenus, pour améliorer son indexation par les moteurs de recherche et offrir une meilleure expérience utilisateur.</p>
        </div>
      </div>

      <div class="seo-item animate__animated animate__fadeIn" style="animation-delay: 0.6s;">
        <img src="/images/link-building.jpg" alt="Link Building" class="seo-image" />
        <div class="seo-text">
          <h2>Link Building</h2>
          <p>Nous mettons en place des stratégies de netlinking pour augmenter la popularité de votre site. Grâce à des liens de qualité, nous améliorons votre autorité de domaine et votre classement dans les résultats de recherche.</p>
        </div>
      </div>

      <div class="seo-item animate__animated animate__fadeIn" style="animation-delay: 0.8s;">
        <img src="/images/technical-seo.jpg" alt="SEO Technique" class="seo-image" />
        <div class="seo-text">
          <h2>SEO Technique</h2>
          <p>Nous nous assurons que votre site est techniquement optimisé, avec une structure claire, des temps de chargement rapides et une compatibilité mobile, pour garantir une performance optimale.</p>
        </div>
      </div>

      <div class="contact-section">
        <h2><strong>Contactez-nous</strong></h2>
        <p>Prêt à améliorer votre visibilité en ligne ? Contactez-nous dès aujourd'hui pour discuter de votre projet et découvrir comment nous pouvons vous aider à atteindre vos objectifs avec une stratégie SEO efficace.</p>
        <br>
        <router-link to="/contact" class="contact-button">Nous Contacter</router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SEOPage',
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');
@import 'animate.css';

.seo-page {
  text-align: center;
  color: #333;
  padding: 225px 20px 20px;
  background-color: inherit;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-heading {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #00aaff;
}

.seo-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.seo-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s;
}

.seo-item:hover {
  transform: translateY(-10px);
}

.seo-image {
  width: 100%;
  height: auto;
  max-width: 800px;
  max-height: 450px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

.seo-text {
  text-align: left;
  max-width: 800px;
}

.seo-text h2 {
  margin-top: 0;
  font-size: 1.8em;
  color: #00aaff;
}

.seo-text p {
  margin-bottom: 25px;
}

.contact-section {
  text-align: center;
  margin-top: 60px;
}

.contact-button {
  display: inline-block;
  background-color: #00aaff;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.contact-button:hover {
  background-color: #00ffff;
  color: #333;
}
</style>
