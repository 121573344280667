<template>
    <div v-if="showBanner" class="cookie-banner">
      <div class="cookie-content">
        <p>
          Nous utilisons des cookies pour améliorer votre expérience sur notre site. Vous pouvez accepter, refuser ou configurer vos préférences.
        </p>
        <div class="cookie-buttons">
          <button @click="acceptCookies" class="cookie-button accept">Accepter</button>
          <button @click="rejectCookies" class="cookie-button reject">Refuser</button>
          <button @click="openSettings" class="cookie-button settings">Configurer</button>
        </div>
        <div v-if="showSettings" class="cookie-settings">
          <label>
            <input type="checkbox" v-model="preferences.analytics" />
            Cookies analytiques
          </label>
          <label>
            <input type="checkbox" v-model="preferences.marketing" />
            Cookies marketing
          </label>
          <button @click="savePreferences" class="cookie-button save">Enregistrer</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showBanner: true,
        showSettings: false,
        preferences: {
          analytics: false,
          marketing: false,
        },
      };
    },
    mounted() {
      // Vérifie si les préférences de cookies sont enregistrées dans le localStorage
      const cookiePreferences = localStorage.getItem('cookiePreferences');
      if (cookiePreferences) {
        const preferences = JSON.parse(cookiePreferences);
        const oneYear = 365 * 24 * 60 * 60 * 1000;
        if (new Date().getTime() - preferences.timestamp > oneYear) {
          // Si plus d'un an s'est écoulé, supprimer les préférences
          localStorage.removeItem('cookiePreferences');
        } else {
          // Si les préférences sont toujours valides, cacher la bannière
          this.showBanner = false;
        }
      }
    },
    methods: {
      acceptCookies() {
        this.setCookies(true, true);
        this.showBanner = false;
      },
      rejectCookies() {
        this.setCookies(false, false);
        this.showBanner = false;
      },
      openSettings() {
        this.showSettings = true;
      },
      savePreferences() {
        this.setCookies(this.preferences.analytics, this.preferences.marketing);
        this.showBanner = false;
      },
      setCookies(analytics, marketing) {
        const preferences = {
          analytics,
          marketing,
          timestamp: new Date().getTime(),
        };
        localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
      },
    },
  };
  </script>
  
  <style scoped>
  .cookie-banner {
    position: fixed;
    bottom: 10px;
    left: 10px;
    right: 10px;
    background-color: rgba(42, 42, 42, 0.95); /* Fond sombre avec opacité */
    color: #fff;
    padding: 20px;
    z-index: 1000;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cookie-content {
    text-align: center;
  }
  
  .cookie-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .cookie-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .accept {
    background-color: #00aaff;
    color: white;
  }
  
  .reject {
    background-color: #ff4d4d;
    color: white;
  }
  
  .settings {
    background-color: #f0ad4e;
    color: white;
  }
  
  .save {
    background-color: #00aaff;
    color: white;
    margin-top: 10px;
  }
  
  .cookie-button:hover {
    opacity: 0.8;
  }
  
  .cookie-settings {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .cookie-settings label {
    margin: 5px 0;
  }
  
  @media (max-width: 600px) {
    .cookie-banner {
      padding: 10px;
    }
  
    .cookie-buttons {
      flex-direction: column;
      gap: 5px;
    }
  
    .cookie-button {
      width: 100%;
    }
  }
  </style>
  